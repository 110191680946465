.logo {
  height: 36px;
  margin: 16px 0;
}

.logo-name {
  margin-left: 10px;
  letter-spacing: 4px;
  font-size: 18px;
  color: #fff;
}

.site-layout .site-layout-background {
  // background: #fff;
}

.ant-layout-header {
  padding: 0px;
  height: 48px !important;
  line-height: 48px !important;
  width: 100%;
  z-index: 9;
  background: #fff !important;
  
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
}
.imgDataBorder{
  img{
    border:1px solid;
  }
}