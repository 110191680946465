.login {
  width: 100%;
  height: 100vh;
  background: url('../images/bg.png') no-repeat;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  width: 800px;
  height: 500px;
  // overflow: hidden;
  background-color: #FFFFFF;
  // margin: -100px 0 0 -250px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  position: relative;
}

.login-circle {
  position: absolute;
  top: 48px;
  right: -15px;
  z-index: 2;
}

.login-left {
  width: 50%;
}

.login-pic {
  width: 100%;
  object-fit: cover;
}

.login-right {
  width: 50%;
  padding: 40px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.login-title {
  font-size: 19px;
  color: #383838;
  margin-bottom: 50px;
}

.login-input {
  height: 40px !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid #EBEEF5 !important;
}

.login-captcha {
  border: 1px solid #ececec;
}

.login-btn {
  width: 100%;
  height: 48px !important;
  margin-top: 30px;
  font-size: 16px !important;
  border: none !important;
  background-color: #FC6713 !important;
}

.login-btn:hover {
  background-color: #ff6d1b !important;
}