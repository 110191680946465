.header-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.header-trigger {
  font-size: 18px;
  cursor: pointer;
}

.header-avatar {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid #ececec;
  object-fit: contain;
  overflow: hidden;
}